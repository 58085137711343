import themeMedia from './theme-media'
import animations from './animations'

themeMedia()
animations()

Promise.all(
    [
        import('./footer-reel'),
        import('./footer-form'),
        import('./login-form'),
        import('./product/section-prod-tabs'),
        import('./product/section-prod-correlati'),
        import('./product/section-prod-archive-filter'),
        import('./product/section-prod-info-gallery'),
        import('./home-intro'),
    ]
).then(
    (
        [
            footerReel,
            footerForm,
            loginForm,
            sectionProdTabs,
            sectionProdCorrelati,
            sectionProdArchiveFilter,
            sectionProdInfoGallery,
            homeIntro,
        ]
    ) => {
        footerReel.default()
        footerForm.default()
        loginForm.default()
        sectionProdTabs.default()
        sectionProdCorrelati.default()
        sectionProdArchiveFilter.default()
        sectionProdInfoGallery.default()
        homeIntro.default()
    }
)