import PubfaCatLogo from '../img/publifarm-cat-logo.svg'
import ArrowRightW from '../img/arrow-right-w.svg'
import ArrowRightG from '../img/arrow-right-g.svg'
import StarFill from '../img/star-fill.svg'
import Search from '../img/search.svg'
import IconProblema from '../img/icon-problema.svg'
import IconSoluzione from '../img/icon-soluzione.svg'
import CaretDownW from '../img/caret-down-w.svg'
import CaretDownG from '../img/caret-down-g.svg'
import CaretUpW from '../img/caret-up-w.svg'
import CaretUpG from '../img/caret-up-g.svg'
import CaretLeftW from '../img/caret-left-w.svg'
import CaretLeftG from '../img/caret-left-g.svg'
import CaretRightW from '../img/caret-right-w.svg'
import CaretRightG from '../img/caret-right-g.svg'

/**
 * Export all the theme images
 */

const themeMedia = () => {
    newImage(PubfaCatLogo)
    newImage(ArrowRightW)
    newImage(ArrowRightG)
    newImage(StarFill)
    newImage(Search)
    newImage(IconProblema)
    newImage(IconSoluzione)
    newImage(CaretDownW)
    newImage(CaretDownG)
    newImage(CaretUpW)
    newImage(CaretUpG)
    newImage(CaretLeftW)
    newImage(CaretLeftG)
    newImage(CaretRightW)
    newImage(CaretRightG)
}

/**
 * Compile image from src folder
 * 
 * @param Image source 
 */

const newImage = (source) => {
    const newImage = new Image()
    newImage.src = source
}

export default themeMedia